.OurTeam-slide-container
{

}

.OurTeam-slide-container .card-container
{

}

.OurTeam-slide-container .card-container .card-box
{
    border-radius: 12px;
    filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.04));
    background-color: #ffffff;
    width: 90%;
    transition: .3s;
}
/* .OurTeam-slide-container .card-container .card-box:hover
{
    cursor: pointer;
    transform: translateY(-15px);
} */

.OurTeam-slide-container .card-container .card-box .person-img
{
    width: 100%;
}

.OurTeam-slide-container .card-container .card-box .title
{
    color: #729BC7!important;

}

.OurTeam-slide-container .card-box .card-arrow
{
  width: 20px;
  height: 20px;
  transform: rotate(308deg);
}

/* .OurTeam-slide-container .disp-content-hover {
    opacity: 0;
    transition: opacity .4s ease-in-out, display 0.5s ease-in-out .3s; 
    display: none;
  }
  
  .OurTeam-slide-container .disp-content-hover-show {
    opacity: 1;
    transition: opacity .4s ease-in-out, display 0.5s ease-in-out .3s; 
    display: block;
  } */
  

  .OurTeam-slide-container .slide {
    position: relative;
    width: 100%;
    overflow-y: hidden;
  }
  .OurTeam-slide-container .slide-in-cus {
    animation: slide-in-cus 0.5s forwards;
    -webkit-animation: slide-in-cus 0.5s forwards;
  }
  .OurTeam-slide-container .slide-out-cus {
    animation: slide-out-cus 0s forwards;
    -webkit-animation: slide-out-cus 0s forwards;
  }
.OurTeam-slide-container .line-seprator-golden
{
  width: 60%;
  height: 0;
  border: 1px solid #A1845D;


}
.OurTeam-slide-container .bottom-arrow-container
{
}

  @keyframes slide-in-cus {
    0% { max-height: 65px; }
    100% { max-height: 600px; }
  }
  
  @-webkit-keyframes slide-in-cus {
    0% { max-height: 65px; }
    100% { max-height: 600px; }
  }
    
  @keyframes slide-out-cus {
    0% { max-height: 600px; }
    100% { max-height:79px; }
  }
  
  @-webkit-keyframes slide-out-cus {
    0% { max-height: 600px; }
    100% { max-height: 78px; }
  }