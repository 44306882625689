.RidingSpaces-container
{
background-color: #292929;
}

.RidingSpaces-container .plain-card
{
    border-radius: 9px;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.24));
    background-color: #3a3a3a;
    height: 100%;
    transition: .3s;
}
.RidingSpaces-container .plain-card:hover
{
    cursor: pointer;
    background-color: transparent;
}
.RidingSpaces-container .plain-card .title
{
    font-size: 22px;
    text-transform: uppercase;
    color: #729bc7;
    font-weight: 400;
    font-family: "Lora";

}

.RidingSpaces-container .plain-card .body-paragraph
{
    width: 95%;
    color:#e5e5e5;
}
.RidingSpaces-container .plain-card .card-img
{
    width:100%;
}