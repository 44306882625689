.main h2,
.main p {
  text-align: start;
  align-self: flex-start;
}
.main p {
  font-size: 16px;
  color: #575757;
  font-weight: 400;
  font-family: "Lato", sans-serif!important;
}
.main strong {
  font-weight: 500;
  font-size: 19px;
}
.main h2 {
  font-size: 33px;
  font-weight: 500;
}
.footer {
  width: 100%;
  padding: 25px 0px 25px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(145, 145, 145);
}
.footer p {
  align-self: flex-end;
}
.footer p span {
  color: rgb(26, 26, 26);
}
.socials {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.para_main img
{
  margin: auto!important;
}
.para_main a
{
 color: #a1845d!important;
}
.socials a {
 width: 25px;
 height: 25px;
  transition: 0.2s ease;
}
.socials img {
  filter: grayscale(1);
  transition: .3s;
  cursor: pointer;
}
.socials img:hover {
  filter: grayscale(0);
}
/* .socials a:hover {
  background-color: red;
} */
.socials a:hover svg {
  fill: #fff;
}
