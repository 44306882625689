.ThoughtfulAmenities-container
{

}

.ThoughtfulAmenities-container .plain-card
{
    border-radius: 9px;
    filter: drop-shadow(0px 3px 15px rgba(112,112,112,0.21));
    background-color: #ffffff;
    height: 100%;
    transition: .3s;
}
.ThoughtfulAmenities-container .plain-card:hover
{
    cursor: pointer;
   transform: translateY(-10px);
}
.ThoughtfulAmenities-container .plain-card .title
{
    font-size: 22px;
    text-transform: uppercase;
    color: #729bc7;
    font-weight: 400;
    font-family: "Lora";

}

.ThoughtfulAmenities-container .plain-card .body-paragraph
{
    width: 95%;

}