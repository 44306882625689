
.idvBlogMain .popular-card-container 
{
    filter: drop-shadow(0 0 20.5px rgba(0,0,0,0.09));
    background-color: #ffffff;
}
.idvBlogMain .popular-card-container .popular-card
{
    cursor: pointer;
    padding-top: 15px;
    transition: .3s;
}
.idvBlogMain .popular-card-container .popular-card:hover
{
}

.idvBlogMain .popular-card-container .popular-card .card-title
{
    font-size: 1rem;
    font-weight: 600;
}
.idvBlogMain .popular-card-container .popular-card .card-date
{
    color:#575757;
}

.idvBlogMain .popular-card-container .blog-card .blog_list_logo_date .card-date
{
    font-size: .9rem;
    color:#575757;
}


.idvBlogMain .popular-card-container .popular-card .popular-blog-img
{
    width: 50%;
}

