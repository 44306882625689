* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: 'Lato', sans-serif;
}

/* font-family: 'Lato', sans-serif;
font-family: 'Lora', serif;
font-family: 'Playfair Display', serif; */


.font-Lora
{
  font-family: 'Lora', serif!important;
 
}
.font-play
{
  font-family: 'Playfair Display', serif!important;
 
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.body-heading, .hero-section-heading { 
  /* font-weight: 700; */
  text-transform: uppercase;
  /* font-family: 'Noto Sans', sans-serif; */
}
.body-heading span, .hero-section-heading span{ 
  /* font-weight: 700; */
  text-transform: uppercase;
  /* font-family: 'Noto Sans', sans-serif; */
}

.body-heading {
  font-size: 39px;
  text-transform: uppercase;
  color: #1e1e1e;
  font-weight: 400;
  font-family: "Lora", sans-serif!important;
  }

.hero-section-heading  
{ 
  font-size: 79px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Playfair Display", sans-serif!important;
}
.hero-section-heading-small
{
  font-size: 29px;
  color: #ffffff;
  font-weight: 400;
}
.hero-section-heading span {font-family: "Playfair Display", sans-serif!important;}
  
.body-paragraph { 
  font-size: 16px;
  color: #575757;
  font-weight: 400;
  font-family: "Lato", sans-serif!important;
}

.hero-paragraph
{
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Lato", sans-serif!important;
}

.hero-genral-btn
{
  padding: .5rem 1.2rem;
  border: none;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  font-family: "Playfair Display", sans-serif!important;
  background: linear-gradient(104deg, #a3865e 0%, #785f3b 100%);
  border-radius: 5px;
  transition:.3s;
}
.hero-genral-btn:hover
{
  
  background: linear-gradient(104deg, #9d825d 0%, #785f3b 100%);

}
  

.genral-btn{ 
  
  padding: .5rem 1.4rem;
  font-size: 14px;
  text-transform: uppercase;
  color: #729bc7;
  font-weight: 700;
  font-family: "Lora", sans-serif!important;
  filter: drop-shadow(0px 3px 3.5px rgba(218,127,27,0.18));
  background: transparent;
  border: 1px solid #729bc7;
  border-radius: 5px;
  transition: .3s;
}
.genral-btn:hover{ 
  background-image: linear-gradient(78deg, #3ab6e7 30%, #238ab2 100%);
  
  border: 1px solid #238ab2;
  color: white;
}

.hero-sub-heading
{
  font-size: 28px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-family: "Lora"!important;
}
.sub-heading
{
  font-size: 22px;
  text-transform: uppercase;
  color: #a1845d;
  font-weight: 700;
  font-family: "Lora"!important;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 37px;
  }

  .hero-section-heading {
    font-size: 50px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 40px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #238ab2;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #238ab2;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #238ab2;
}

::-moz-scrollbar-thumb:hover {
  background: #238ab2;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #9dcee1;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #9dcee1;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #238ab2;
}

::-moz-scrollbar-thumb:active {
  background: #238ab2;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #9dcee1;
}
