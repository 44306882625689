.InstaImageGallery-container
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.InstaImageGallery-container .testimonial-card
{
    cursor: pointer;
}
.InstaImageGallery-container .testimonial-card .arrow-img
{
    width:45px;
}


.InstaImageGallery-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.InstaImageGallery-container .profile-info
{
    
}


.InstaImageGallery-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.InstaImageGallery-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.InstaImageGallery-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.InstaImageGallery-container .swiper-pagination
{
    /* width: fit-content!important; */
}
.InstaImageGallery-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #3A3A3A;
    opacity: 1;
}
.InstaImageGallery-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #A1845D;
    opacity: 1;
}

.InstaImageGallery-container .swiper-slide-next img
{
    /* height: 400px!important; */
}
.InstaImageGallery-container .testimonials-list img
{
    cursor: pointer;
}


.InstaImageGallery-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    left: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%, rgb(255, 255, 255) 100%);
}
.InstaImageGallery-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    right: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%, rgb(255, 255, 255) 100%);

}

.InstaImageGallery-container .instaCardBox
{
    background-color: #ffffff;
    text-align: center;
    /* padding-bottom: 15px; */
}
.InstaImageGallery-container .instaCardBox .title
{
    text-transform: uppercase;
    color: #1e1e1e;
    font-weight: 400;
    font-family: "Lora";
}
.InstaImageGallery-container .instaCardBox .para
{
    line-height: 20px;
    color: #575757;
    font-weight: 400;
    font-family: "Lato";
}

.InstaImageGallery-container .instaCardBox .content-box
{
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.InstaImageGallery-container .swiper-slide-next .instaCardBox .content-box
{
    opacity: 1;
}
.InstaImageGallery-container .swiper-slide-next .instaCardBox
{
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
    opacity: 1;
}

.InstaImageGallery-container .swiper-wrapper
{
    margin-bottom: 20px;
}
.InstaImageGallery-container .swiper-button
{
    position: absolute;
    top: 30%;
    z-index: 9999;
    color: #A1845D!important;
}

@media(max-width: 1000px)
{
    
.InstaImageGallery-container .card-list::before
{
    content: none;
}
.InstaImageGallery-container .card-list::after
{
    content: none;
}
    .InstaImageGallery-container .instaCardBox .content-box
    {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    .InstaImageGallery-container .swiper-slide-active .instaCardBox .content-box
    {
        opacity: 1;
    }
}


@keyframes hide {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}