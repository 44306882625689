.facility-container
{
    background-image: url("../../../public/images/creatives/our-facility-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
    margin-bottom: 150px;
}

.facility-container .images-section
{
    transform: translateY(150px);
}

.facility-container .text-content 
{
    transform: translateY(80px);
}

@media(max-width:992px)
{
    .facility-container .images-section
    {
        transform: translateY(0);
        margin-top: 30px;
    }
    .facility-container .text-content 
{
    padding-top: 20px;
    transform: translateY(0);
}
.facility-container
{
    margin-bottom: 0px;
}
}