.popular-blog-container
{

}

.popular-blog-container .body-heading-b
{
    font-size: 2rem;
}

.popular-blog-container .blog-card
{
    padding: 10px 4px;
    filter: drop-shadow(0 0 20.5px rgba(0,0,0,0.09));
    background-color: #ffffff;
    transition: .3s;
}
.popular-blog-container .blog-card:hover
{
}
.popular-blog-container .blog-card .blog_list_logo
{
    gap: 10px;
}
.popular-blog-container .blog-card .blog_list_logo_img
{
    width: 60px;
    height: 60px;
}

.popular-blog-container .blog-card .blog_list_logo_title
{
    font-size: 1.4rem;
    font-weight: 600;
}

.popular-blog-container .blog-card .blog_list_logo_date
{
    font-size: .9rem;
    color:#fcfcfc;
}
.popular-blog-container .blog-card .blog_list_logo_date span
{
    margin-left: 5px;
    font-weight: 600;
}

.popular-blog-container .blog-card .card-anchor
{
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}

.popular-blog-container .blog-card .color-blue
{
    color:#2245D1;
}

.popular-blog-container .blog-card .card-img
{
}

.popular-blog-container .popular-card-container
{
    filter: drop-shadow(0 0 20.5px rgba(0,0,0,0.09));
    background-color: #ffffff;
}

.popular-blog-container .popular-card .card-title
{
    font-size: 1rem;
    font-weight: 600;
}
.popular-blog-container .popular-card .card-date
{
    color:#575757;
}

.popular-blog-container .blog-card .blog_list_logo_date .card-date
{
    font-size: .9rem;
    color:#D8D8D8;
}


.popular-blog-container .popular-card .popular-blog-img
{
    width: 50%;
}

.popular-blog-container .page-btn-container button
{
    padding:7px 14px;
    border: 1px solid #B9B9B9;
    background-color: transparent;
    color: #575757;
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
}

.popular-blog-container .page-btn-container button .next-icon
{
    width: 10px;
    height: 10px;
    margin: 0;
}
.popular-blog-container .page-btn-container .active
{
    background-color: #729BC7;
    color: white;
}