.Testimonials-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(41,41,41,0 );
    background: linear-gradient(90deg, 
    /* rgba(41,41,41,0 ) 0.00%, 
    rgba(41,41,41,0.51 ) 23.94%,  */
    #292929 0.00%, 
    #292929 74.46%, 
    #292929 100.00%);
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    
}


.Testimonials-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.Testimonials-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.Testimonials-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.Testimonials-container .swiper-pagination
{
    /* width: fit-content!important; */
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #3A3A3A;
    opacity: 1;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #A1845D;
    opacity: 1;
}

.Testimonials-container .swiper-slide-next .sec
{
    height: 400px!important;
}
.Testimonials-container .swiper-wrapper
{
    align-items: end!important;
}

.Testimonials-container .testimonials-list .sec
{
    width: 400px;
    height: 365px;
    cursor: pointer;
}


.Testimonials-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    left: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(41,41,41,0) 0%, rgba(41, 41, 41, 0.406) 24%, rgba(41, 41, 41, 0.631) 46%, rgba(41, 41, 41, 0.863) 74%,rgba(41, 41, 41, 1) 90%, rgb(41, 41, 41) 100%);
}
.Testimonials-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    right: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(41,41,41,0) 0%, rgba(41,41,41,0.406) 24%, rgba(41,41,41,0.631) 46%, rgba(41,41,41,0.863) 74%,rgba(41, 41, 41, 1) 90%, rgba(41,41,41,0.9999999999999999) 100%);

}

.Testimonials-container .swiper-slide
{
    display: flex;
    justify-content: center;
}

@media(max-width: 1000px)
{
.Testimonials-container .testimonials-list .sec
{
    width: 350px;
    height: 340px;
}
.Testimonials-container .swiper-slide-next .sec
{
    height: 340px!important;
}
.Testimonials-container .card-list::before
{
    content: none;
}
.Testimonials-container .card-list::after
{
    content: none;
}
}
