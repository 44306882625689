.OurTeam-container
{

}

.OurTeam-container .card-container
{

}

.OurTeam-container .card-container .card-box
{
    border-radius: 12px;
    filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.04));
    background-color: #ffffff;
    width: 90%;
    transition: .3s;
}
.OurTeam-container .card-container .card-box:hover
{
    cursor: pointer;
    transform: translateY(-15px);
}

.OurTeam-container .card-container .card-box .person-img
{
    width: 100%;
}

.OurTeam-container .card-container .card-box .title
{
    color: #729BC7!important;

}