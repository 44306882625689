.ScrollingTextContent-container
{
}

.ScrollingTextContent-container .genral-btn
{
    background-color: #203354;
    color: white;
    transition: .3s;
}
.ScrollingTextContent-container .btn-y
{
    background-color: #E1A92A!important;
    color: white;
    transition: .3s;
}

.ScrollingTextContent-container .text-content .heading
{
    color: #203354;
}

.ScrollingTextContent-container .text-content .content
{
    color: #454545;
}

.ScrollingTextContent-container .scroll-content
{
    height: 330px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
}